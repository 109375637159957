import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

const query = graphql`
  {
    strapiImprint {
      content
    }
  }
`
const Imprint = () => (
  <StaticQuery
    query={query}
    render={data => {
      return (
        <Markdown
          children={data.strapiImprint.content}
          remarkPlugins={[remarkGfm]}
          className="article mt-2 leading-6"
        />
      )
    }}
  />
)

export default Imprint
