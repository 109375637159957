import * as React from "react"

import Layout from "components/layout"
import Headline from "components/general/headline"
import Imprint from "components/imprint"

const ImprintPage = () => {
  const seo = {
    metaTitle: "Impressum",
    metaDescription:
      "Impressum der Homepage der Faustball Sparte des MTV Vorsfelde",
  }

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline headline="Impressum" subHeadline={undefined} />

        <Imprint />
      </div>
    </Layout>
  )
}

export default ImprintPage
